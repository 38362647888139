.jcf-header {
    padding-left: 20px;
    padding-right: 20px;
}
.jcf-header-icon {
    margin-top: 7px;
    height: 26px;
}

.jcf-content {
    max-width: 1200px;
    /* background: rgb(199, 199, 199); */
    margin: auto;
    padding: 10px;
}
.jcf-hero {
    text-align: center;
    background: #f5f7fa;
    padding: 80px 10px 100px 10px;
}
.jcf-hero .photo_icon {
    width: 50px;
}
.jcf-hero h1{ 
    color: #149266;
    text-align: center;
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    font-size: 48px;
    line-height: 54px;
    font-weight: 700;
    letter-spacing: -0.03em;
}
.jcf-hero p{ 
    color: #677483;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    text-align: center;
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
}
.jcf-hero img{ 
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
}
.jcf-footer {
    text-align: center;
    background: #f5f7fa;
    padding: 50px 10px 40px 10px;
}
.jcf-footer .jcf-footer-icon {
    display: block;
    font-size: 55px;
    color: #396394;
    margin: auto;
}
.jcf-footer .icon-last-child {
    margin-top: 40px;
}
.jcf-footer h1 {
    font-size: 32px;
    line-height: 54px;
    font-weight: 600;
    letter-spacing: -0.03em;
    margin-bottom: 30px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.jcf-footer p {
    color: #677483;
    font-size: 18px;
    letter-spacing: -0.03em;
    margin-top: 10px;
}
.jcf-footer .p-first-child {
    margin-bottom: 4px;
}
.jcf-footer .span {
    color: #8b8b8b;
    font-size: 80%;
}
.restoring_icon {
    width: 60px;
}
.jfc-card-title {
    color: #5a5a5a !important;
    font-size: 28px !important;
    font-weight: 600;
    margin-bottom: 12px;
    max-width: 600px;
}
.jcf-hero span {
    font-size: 70%;
}